import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';

// markup
const EttevottestPage = () => {
  const data = useStaticQuery(graphql`
  query { 
    allFile(filter: {sourceInstanceName: {eq: "kutsetunnistus"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(height: 300, quality: 80)
        id
        original {
          src
        }
      }
    }
  }
  }
`);

  const imgs = data
    && data.allFile
    && data.allFile.nodes
    && data.allFile.nodes.map((n) => ({ ...n.childImageSharp }));

  return (
    <Layout pageTitle="Ettevõttest">
      <section className="section">
        <div className="container mr-5 ml-5 ">
          <h2 className="subtitle">Ettevõttest</h2>
          <div className="block">
            Ahjud Pro on väikeettevõte Eesti turul, kelle tegevusalaks on kõikvõimalikud pottsepatööd.
            Pottsepp pakub professionaalset nõustamist ja kvaliteetset teenust küttekollete valdkonnas.
            Pottsepatööd ja -teenused Pärnumaal, üle Eesti.
          </div>

          <h2 className="subtitle">Pottseppmeistrist</h2>

          <div className="block">
            “Olen lõpetanud Tallinna Tehnikakooli nr. 19 pottsepp-müürsepp erialal ja nüüdseks on töökogemust üle 20 aasta. Aastatega on valminud sadu erinevaid küttekoldeid. Olen osalenud erinevatel koolitustel ja omandanud kutse
            {' '}
            <a target="_blank" href="https://www.kutseregister.ee/ctrl/et/Tunnistused/vaata/10645111/1" referrerPolicy="no-referrer">Pottseppmeister tase 5</a>
            ".
            <br />
            <p>
              "Pottsepatöö on käsitöö, mis minu jaoks põhineb teadmistel, kogemustel ja ka täpsusel.
              Ilma nendeta on keeruline saavutada head ja kliendile rahulolu pakkuvat lõpptulemust.
              Pottsepatöö on minu jaoks vaheldusrikas, sest tegemist on loomingulise protsessiga ja iga töö on omamoodi ainulaadne ning see teebki mulle huvitavaks ja meeldivaks.
              Mulle meeldib oma kätega luua soojust ja hubasust.”
            </p>
          </div>

          <h2 className="subtitle">Pottsepatööd</h2>

          <div className="block content">
            <ul>
              <li>valmis ahjude, kaminate, pliitide paigaldus</li>
              <li>pottahju ehitus</li>
              <li>plekkahju ehitus</li>
              <li>soemüüri ehitus (tellis, pott)</li>
              <li>puupliidi ehitus (tellis, pott)</li>
              <li>kamina ehitus</li>
              <li>korstende ehitus ja paigaldus</li>
              <li>eritellimused, suveköögid</li>
              <li>kõigi küttekollete remont</li>
              <li>vanade amortiseerunud kollete lammutus ja utiliseerimine</li>
            </ul>
          </div>

          <h2 className="subtitle">Eksperthinnang küttesüsteemile</h2>

          <div className="block">
            Küttekolde eksperthinnang sisaldab litsentseeritud pottsepa hinnangut küttekolde tuleohutusele ja vastavusest seadusele.
            <br />
            Küttesüsteemi ekspertiis on vajalik hoone omanikule, et tagada küttekolde ohutu kasutamine. Lisaks on eksperthinnang vajalik kasutusloa taotlejale või kui küttekoldel puudub dokumentatsioon.
          </div>

          <h2 className="subtitle">Konsultatsioon</h2>
          <div className="block">
            Huvi korral võtke ühendust ja leiame koos sobiva lahenduse.
            Koostame detailse hinnapakkumise ja teostame kõik vajalikud tööd.
            <br />
            Töö hind sõltub tellimuse suurusest, idee lennukusest ja ajamahukusest.
            <br />
            <strong>Esmasel konsultatsioonil on visiit kliendi koju Pärnus tasuta, maakonniti üle Eesti kokkuleppel.</strong>
          </div>
          <h2 className="subtitle">Garantii</h2>
          <div className="block">
            Tööd teostab kogemustega sertifitseeritud pottseppmeister.
            Kõigi teostatud töödega kaasneb garantii koos ajakohase dokumentatsiooniga.
          </div>
          <div className="mt-6">
            <Gallery autoSize photos={imgs} imgStyles={{ cursor: 'pointer', display: 'block' }} />
          </div>
        </div>

      </section>
    </Layout>
  );
};

export default EttevottestPage;
